<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <b-card>
          <b-row>
            <b-col sm="4">
              <!-- PERMISSION TABLE -->
              <b-card no-body class="border p-1">
                <b-row>
                  <b-col cols="12">
                    <b-form-group label-cols="4" label-cols-lg="5" label-size="sm" label="NIP" label-for="input-sma">
                      <b-form-input id="input-sma" v-model="nip" size="sm" placeholder="nip" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label-cols="4" label-cols-lg="5" label-size="sm" label="Nama Pejabat" label-for="input-smv">
                      <b-form-input id="input-smv" v-model="pejabat" size="sm" placeholder="nama" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label-cols="4" label-cols-lg="5" label-size="sm" label="Jabatan" label-for="input-smf">
                      <b-form-input v-model="jabatan" id="input-smf" size="sm" placeholder="Jabatan" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label-cols="4" label-cols-lg="5" label-size="sm" label="TTD Digital" label-for="input-smbf">
                      <b-form-file v-model="ttd_digital" size="sm" placeholder="File..." drop-placeholder="Drop file ." @change="uploadFile" ref="file" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label-cols="4" label-cols-lg="5" label-size="sm" label="Status" label-for="input-smsad">
                      <b-form-select v-model="status" :options="status_opt" label="text" size="sm" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Disclaimer" label-for="Disclaimer">
                      <b-form-textarea id="textarea-dislaimer" placeholder="Textarea" rows="8" v-model="dislaimer" />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-button v-if="insert_status" size="sm" @click="submitData" variant="gradient-success" class="mb-2">
                      Submit
                    </b-button>
                    <b-button v-if="update_status" size="sm" @click="updateData" variant="gradient-success" class="mb-2">
                      Update
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>

            <b-col cols="8">
              <b-card no-body class="border">
                <!-- <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon icon="FolderIcon" size="14" />
                <span class="align-middle ml-50 text-dark h6">Selectbox</span>
              </b-card-title>
            </b-card-header> -->
                <!-- Card1 -->
                <b-row class="p-1">
                  <div class="table-responsive text-nowrap">
                    <b-table responsive hover class="mb-0" :items="items">
                      <template #cell(ttd_digital)="data">
                        <!-- <b-avatar :src="data.value" /> -->
                        <img :src="data.value" style="width:80px;height:30px" />
                      </template>
                      <template #cell(status)="data">
                        <b-badge :variant="status_table[1][data.value]">
                          {{ status_table[0][data.value] }}
                        </b-badge>
                      </template>
                      <template #cell(disclaimer)="data">
                        <b-form-textarea rows="2" disabled="true" :value="data.value"></b-form-textarea>
                      </template>

                      <template #cell(path)="data">
                        <b-button-group>
                          <b-button variant="success" class="btn-icon rounded-circle" size="sm" @click="onActionEdit(data.value)">
                            <edit-2-icon size="1x" />
                          </b-button>
                          <b-button variant="danger" class="btn-icon rounded-circle" size="sm" @click="onActionDelete(data.value)">
                            <trash-icon size="1x" />
                          </b-button>
                        </b-button-group>
                      </template>
                    </b-table>
                    <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
                  </div>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BCardHeader, BCardTitle, BFormSelect, BTable, BFormFile, BFormTextarea, BOverlay, BBadge } from "bootstrap-vue";
import vSelect from "vue-select";
import { TrashIcon, Edit2Icon } from "vue-feather-icons";
import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import helper from "@/api/helper";
export default {
  components: {
    BFormTextarea,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormSelect,
    vSelect,
    BTable,
    TrashIcon,
    Edit2Icon,
    BFormFile,
    BOverlay,
    BBadge,
  },

  data() {
    return {
      showLoading: false,
      selected: { title: "Square" },
      option: [{ title: "Square" }, { title: "Rectangle" }, { title: "Rombo" }, { title: "Romboid" }],
      dataImg: null,
      ttd_digital: null,
      // table
      status: "",
      dislaimer: "",
      jabatan: "",
      pejabat: "",
      nip: "",
      pejabat_id: "",
      status_opt: [
        {
          text: "Aktif",
          value: 1,
        },
        {
          text: "Non-Aktif",
          value: 0,
        },
      ],
      items: [],

      status_table: [
        { 0: "Non-Aktif", 1: "Aktif" },
        { 1: "light-success", 0: "light-danger" },
      ],
      token: "",
      row_update: {},
      insert_status: true,
      update_status: false,
      path: "",
    };
  },
  mounted() {
    this.token = jwt.getToken();
    this.status = 1;
    this.getListPejabat();
  },
  computed: {
    // dataUrl(){
    //   // his.info.image = 'data:image/jpeg;base64,' + btoa(response.data.results[0].image.data);
    //     return 'data:image/jpeg;base64,' + btoa(
    //         new Uint8Array(this.dataImg)
    //         .reduce((data, byte) => data + String.fromCharCode(byte), '')
    //     );
    // }
  },
  methods: {
    //    const app = new Vue({
    //   data: () => ({images: null}),
    //   template: `
    //     <div>
    //       <input type="file" @change="uploadFile" ref="file">
    //       <button @click="submitFile">Upload!</button>
    //     </div>
    //   `,
    //   methods: {
    submitData() {
      this.pejabat_id = "";
      if (this.dataImg == null) {
        alert("ttd digital kosong");
        return;
      }
      if (this.nip == "" || this.pejabat == "" || this.jabatan == "") {
        return;
      }
      // console.log(this.status)
      // return
      this.showLoading = true;
      var url = helper.getBaseUrl() + "/esign";
      var data = {
        "@type": "Esign",
        nip: this.nip,
        nama_pejabat: this.pejabat,
        jabatan: this.jabatan,
        esign_status: this.status,
        disclaimer: this.dislaimer,
      };
      axios
        .post(url, data, helper.getConfig())
        .then((response) => {
          console.log(response);
          console.log(response.data["@name"]);
          this.pejabat_id = response.data["@name"];
          this.showLoading = false;
          this.submitFile();
        })
        .catch((error) => {
          console.log(error);
          this.showLoading = false;
        });
    },
    uploadFile(e) {
      this.dataImg = null;
      this.dataImg = e.target.files[0];
    },
    submitFile() {
      // var url = "http://116.90.165.45:7080/db/bmkgsatu/1/1/20/1/metaphoto/111_1/@upload/image"

      var url = helper.getBaseUrl() + "/esign/" + this.pejabat_id + "/@upload/image";
      var config = {
        method: "patch",
        url: url,
        headers: {
          Authorization: `Bearer  ${this.token}`,
          "Content-Type": this.dataImg.type,
        },
        data: this.dataImg,
      };
      this.showLoading = true;
      axios(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          this.dataImg = null;
          this.showLoading = false;
          this.resetForm();
          this.getListPejabat();
        })
        .catch((error) => {
          console.log(error);
          this.dataImg = null;
          this.showLoading = false;
        });
    },
    getListPejabat() {
      var url = helper.getBaseUrl() + "/@search?type_name=Esign&esign_status__not=2";
      // var url = helper.getBaseUrl() +"/@search?type_name=Esign"
      var config = {
        method: "get",
        url: url,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer  ${this.token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          "Access-Control-Allow-Credentials": true,
        },
      };
      this.showLoading = true;
      axios(config)
        .then((response) => {
          // console.log(response.data.items);
          this.items = [];
          var row_up = {};
          // console.log("res : ",response.data.items)
          for (var i in response.data.items) {
            var data = response.data.items[i];
            var ttd_digital_local = helper.getNotImages();
            //
            console.log("ttd na : ", data);
            if (data.ttd_digital != null) {
              ttd_digital_local = "data:image/jpeg;base64," + data.ttd_digital;
            }
            i++;
            var row = {
              no: i,
              nip: data.nip,
              nama_pejabat: data.nama_pejabat,
              jabatan: data.jabatan,
              disclaimer: data.disclaimer,
              ttd_digital: ttd_digital_local,
              status: data.esign_status,
              path: data.path,
            };
            this.items.push(row);
            row_up[data.path] = {
              no: i,
              nip: data.nip,
              nama_pejabat: data.nama_pejabat,
              jabatan: data.jabatan,
              disclaimer: data.disclaimer,
              ttd_digital: ttd_digital_local,
              status: data.esign_status,
              path: data.path,
              pejabat_id: data.id,
            };
          }
          this.row_update = row_up;
          console.log("data row : ", this.items);
          this.showLoading = false;
        })
        .catch((error) => {
          console.log("errornya : ", error);
          this.showLoading = false;
        });
    },

    updateData() {
      if (this.nip == "" || this.pejabat == "" || this.jabatan == "") {
        return;
      }
      console.log("ada pej id : ", this.pejabat_id);
      this.showLoading = true;
      var url = helper.getBaseUrl() + this.path;
      var data = {
        nip: this.nip,
        nama_pejabat: this.pejabat,
        jabatan: this.jabatan,
        esign_status: this.status,
        disclaimer: this.dislaimer,
      };
      axios
        .patch(url, data, helper.getConfig())
        .then((response) => {
          this.showLoading = false;

          if (this.dataImg != null) {
            this.submitFile();
          }
          this.resetForm();
          this.getListPejabat();
          this.insert_status = true;
          this.update_status = false;
        })
        .catch((error) => {
          console.log(error);
          this.showLoading = false;
        });
    },
    resetForm() {
      this.path = "";
      this.nip = "";
      this.pejabat = "";
      this.status = "";
      this.jabatan = "";
      this.dislaimer = "";
      this.pejabat_id = "";
    },
    onActionEdit(path) {
      this.insert_status = false;
      this.update_status = true;
      var data = this.row_update[path];
      this.path = path;
      this.nip = data.nip;
      this.pejabat = data.nama_pejabat;
      this.status = data.status;
      this.jabatan = data.jabatan;
      this.dislaimer = data.disclaimer;
      this.pejabat_id = data.pejabat_id;
      this.dataImg == null;
      console.log("data : ", data);
      console.log("pejabat id : ", this.pejabat_id);
    },
    onActionDelete(path) {
      this.$swal({
        title: "Apakah Anda Yakin?",
        text: "Apakah Anda Yakin untuk melakukan penghapusann data??",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-dark ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteData(path);
        } else if (result.dismiss === "cancel") {
        }
      });
    },
    deleteData(path) {
      this.showLoading = true;

      var url = helper.getBaseUrl() + path;
      var data = {
        esign_status: 2,
      };
      axios
        .patch(url, data, helper.getConfig())
        .then((response) => {
          this.showLoading = false;
          this.getListPejabat();
        })
        .catch((error) => {
          console.log(error);
          this.showLoading = false;
        });
    },
  },
  watch: {
    // nip(){},
    // pejabat(){},
    // status(){},
    // jabatan(){},
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style>
.custom-file-label {
  border-radius: 0.357rem;
  border: 2px solid #6e6b7b;
}
</style>
